import React from 'react';
import Article from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';

const Content = () => {
  return (
    <div>
      <p>
        Let’s say you install a script on domains example.com and blog.example.com. When the user
        goes from one domain to another, it will be registered as two separate sessions under two
        different IDs.
      </p>
      <p>
        In this situation, LiveSession sees the same person as two separate users. Here’s how you
        can solve this issue:{' '}
      </p>
      <p className="alert alert-primary mb-5 mt-5" role="alert">
        To track users across multiple subdomains, you have to set a{' '}
        <Code inline>rootHostname</Code> option to{' '}
        <strong>your top-level domain (eg. .your-domain.com)</strong> when calling the{' '}
        <Code inline>__ls(&quot;init&quot;)</Code> method. Please note that the first character in
        .your-domain.com is a dot.
      </p>
      <p>Example:</p>
      <p>
        Your main domain is <i>mywebsite.com</i> but you also want to record on{' '}
        <i>blog.mywebsite.com</i> :
      </p>
      <Code
        lang="javascript"
        style={{ margin: '2rem 0' }}
      >{`__ls("init", "YOUR_TRACK_ID", { rootHostname: '.mywebsite.com' })`}</Code>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-record-keystrokes/',
    title: "How to record user's keystrokes?",
    description: 'See how to enable global keystroke tracking.',
  },
];

export const frontmatter = {
  metaTitle: 'How to track users across multiple subdomains?',
  metaDescription:
    'Learn how to track users across multiple subdomains with LiveSession. Watch the whole journey in one recording.',
  canonical: '/help/how-to-track-users-across-multiple-subdomains/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
